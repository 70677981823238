import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
// Libs
import _ from 'lodash';
// Antd
import { Breadcrumb } from 'antd';
// Context
import { ConfigContext } from 'src/contexts/ConfigContext';
// Types
import { Parents } from 'types';
// Styles
import stylesheet from './Breadcrubs.module.scss';

export function Breadcrumbs() {
  const location = useLocation();
  const navigate = useNavigate();
  const { projects, projectGroups, clusterParents, sunburstParents } = useContext(ConfigContext);
  const { projectId, groupId, clusterId, chartId } = useParams();

  const { pathname, search } = location;
  const title = new URLSearchParams(search).get('title');
  const pathnames = pathname.split('/').filter((item) => item);
  const [clusterInfo, setClusterInfo] = useState<Parents>(clusterParents);

  useEffect(() => {
    setClusterInfo(clusterParents);
  }, [clusterParents]);

  const { parents, parentNames } = useMemo(() => {
    return clusterInfo;
  }, [clusterInfo]);

  const itemRender = (route: any) => {
    return (
      <span
        title={route.tooltip ?? null}
        style={{ ...(route.disabled ? { cursor: 'default' } : {}) }}
        className={stylesheet.linkItem}
        onClick={(e) => {
          // This is imitates Link behaviour
          e.preventDefault();
          !route.disabled && navigate(route.href);
        }}>
        {route.title}
      </span>
    );
  };

  const projectName = useMemo(() => {
    if (projects && projectId) {
      return _.find(projects, ['project_uuid', projectId])?.title ?? '...';
    }
    return '';
  }, [projects, projectId]);

  const groupName = useMemo(() => {
    if (projectGroups && groupId) {
      return _.find(projectGroups, ['id', _.toNumber(groupId)])?.title ?? '...';
    }
    return '';
  }, [projectGroups, groupId]);

  if (!projectId) return null;

  const items = [];
  // Skip Projects screen if only one project aveilable
  if (projects.length !== 1) items.push({ title: 'Projects', href: '/' });
  if (pathnames.includes('groups') && projectId) items.push({ title: projectName, href: `/projects/${projectId}/groups` });
  if (!pathnames.includes('sequences') && projectId && groupId && !clusterId) items.push({ title: groupName, href: `/projects/${projectId}/groups/${groupId}` });
  if ((pathnames.includes('sequences') || pathnames.includes('clusters')) && projectId && groupId) items.push({ title: groupName, href: `/projects/${projectId}/groups/${groupId}` });
  if (pathnames.includes('sequences') && projectId && groupId) items.push({ title: 'Sequences', href: `/projects/${projectId}/groups/${groupId}/sequences` });
  if (pathnames.includes('structure') && projectId && groupId) items.push({ title: `Structure${title ? ` - ${title}` : ''}`, href: `/projects/${projectId}/groups/${groupId}/sequences/structure${search}` });
  if (pathnames.includes('compare') && projectId && groupId) items.push({ title: `Compare${title ? ` - ${title}` : ''}`, href: `/projects/${projectId}/groups/${groupId}/sequences/compare` });
  if (pathnames.includes('clusters') && projectId && groupId && clusterId && parents?.length) {
    parents.forEach((item: string, index: number) => {
      const clusterName = parentNames[item] || item;
      const title = _.truncate(clusterName, { length: 15, omission: '...' });

      if (chartId && index > 0) return;
      items.push({ title: index === 0 ? 'Cluster' : title, href: `/projects/${projectId}/groups/${groupId}/clusters/${item}`, tooltip: clusterName });
    });
  }
  if (pathname.includes('chart') && pathnames.includes('clusters') && projectId && groupId && clusterId) {
    items.push({ title: 'Sunburst', href: `/projects/${projectId}/groups/${groupId}/clusters/${clusterId}/chart/${clusterId}` });

    sunburstParents.parents?.forEach((item: string, index: number) => {
      const clusterName = sunburstParents.parentNames[item] || item;
      const title = _.truncate(clusterName, { length: 15, omission: '...' });

      if (index === 0) return;
      items.push({ title: index === 0 ? 'Sunburst' : title, href: `/projects/${projectId}/groups/${groupId}/clusters/${clusterId}/chart/${item}`, disabled: sunburstParents.parents.length - 1 === index, tooltip: clusterName });
    });
  };
  if (pathnames.includes('msa') && pathnames.includes('sequences') && projectId && groupId) items.push({ title: 'MSA Viewer', href: `/projects/${projectId}/groups/${groupId}/sequences/msa` });
  if (pathnames.includes('msa') && pathnames.includes('clusters') && projectId && groupId) items.push({ title: 'MSA Viewer', href: `/projects/${projectId}/groups/${groupId}/clusters/msa` });

  return (
    <div className={stylesheet.wrapper}>
      <Breadcrumb itemRender={itemRender} items={items} />
    </div>
  );
};
