import React from 'react';
// Antd
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// Styles
import stylesheet from './Loader.module.scss';

export function Loader({ size = 48, message, styles = {} }: { size?: number, message?: string, styles?: React.CSSProperties }) {
  return (
    <div className={stylesheet.root}>
      <Spin indicator={(
        <LoadingOutlined style={{ fontSize: size, ...styles }} spin />
      )}/>
      {message && <div className={stylesheet.loadingText}>{message}</div>}
    </div>
  );
}
