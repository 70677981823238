import { lazyWithPreload } from 'react-lazy-with-preload';

export { EmptyPage } from './EmptyPage';
export { ErrorPage } from './ErrorPage';
export { Login } from './Login';

export const Groups = lazyWithPreload(async () =>
  await import(/* webpackChunkName: 'page-groups' */ './Groups')
    .then(({ Groups }) => ({ default: Groups }))
);

export const StatisticsView = lazyWithPreload(async () =>
  await import(/* webpackChunkName: 'page-group-details' */ './StatisticsView')
    .then(({ StatisticsView }) => ({ default: StatisticsView }))
);

export const Projects = lazyWithPreload(async () =>
  await import(/* webpackChunkName: 'page-projects' */ './Projects')
    .then(({ Projects }) => ({ default: Projects }))
);

export const SequenceView = lazyWithPreload(async () =>
  await import(/* webpackChunkName: 'page-sequence-details' */ './SequenceView')
    .then(({ SequenceView }) => ({ default: SequenceView }))
);

export const ClusterView = lazyWithPreload(async () =>
  await import(/* webpackChunkName: 'page-cluster-details' */ './ClusterView')
    .then(({ ClusterView }) => ({ default: ClusterView }))
);

export const StructureView = lazyWithPreload(async () =>
  await import(/* webpackChunkName: 'page-structure-details' */ './StructureView')
    .then(({ StructureView }) => ({ default: StructureView }))
);

export const CompareView = lazyWithPreload(async () =>
  await import(/* webpackChunkName: 'page-compare-details' */ './CompareView')
    .then(({ CompareView }) => ({ default: CompareView }))
);

export const MSAView = lazyWithPreload(async () =>
  await import(/* webpackChunkName: 'page-msa-details' */ './MSAView')
    .then(({ MSAView }) => ({ default: MSAView }))
);

export const SunburstChartView = lazyWithPreload(async () =>
  await import(/* webpackChunkName: 'page-sunburts-chart' */ './SunburstChartView')
    .then(({ SunburstChartView }) => ({ default: SunburstChartView }))
);
