import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
// Libs
import _ from 'lodash';
// Antd
import { notification } from 'antd';
// API
import { sequenceApi, SEQUENCES_QUERY_LIMIT } from 'api';
// Context
import { AuthContext } from 'common/AuthProvider';
// Types
import { Cluster, Config, Ligand, Parents, Project, SequenceGroupData, SequenceInfo, SequenceTag, Tag } from 'types';
import { ColumnFilter } from 'components/SequenceTable/types';
import { AxiosError } from 'axios';
// Helpers
import { extractURLParam } from './helpers';

interface ConfigContextType {
  config: Config;
  ligands: Ligand[];
  refreshLigands: () => void;
  tags: Tag[];
  tagLabels: Record<string, SequenceTag[]>;
  refreshTags: () => void;
  sequenceGroupSource: (string | number)[];
  filter: Record<string, ColumnFilter>;
  setFilter: (filter: Record<string, ColumnFilter>) => void;
  projects: Project[];
  projectGroups: SequenceGroupData;
  setProjectGroups: (
    projectGroups: SequenceGroupData | ((prevState: SequenceGroupData) => SequenceGroupData)
  ) => void;
  projectGroupsLoading: boolean;
  groupInfo: SequenceInfo;
  groupLoading: boolean;
  setGroupInfo: (
    projectGroups: SequenceInfo | ((prevState: SequenceInfo) => SequenceInfo)
  ) => void;
  clusterParents: Parents;
  sunburstParents: Parents;
  setClusterParents: (
    clusterParents: Parents | ((prevState: Parents) => Parents)
  ) => void;
  fetchProjectGroups: () => void;
  querySequences: Record<string, Cluster[]>;
  fetchQuerySeqs: (id: number) => void;
}

const ConfigContext = createContext<ConfigContextType>({
  config: {},
  ligands: [],
  refreshLigands: () => {},
  tags: [],
  tagLabels: {},
  refreshTags: () => {},
  sequenceGroupSource: [],
  filter: {},
  setFilter: () => {},
  projects: [],
  projectGroups: [],
  setProjectGroups: () => {},
  projectGroupsLoading: true,
  groupInfo: {
    title: '',
  },
  groupLoading: false,
  setGroupInfo: () => {},
  clusterParents: { parentNames: {}, parents: [] },
  sunburstParents: { parentNames: {}, parents: [] },
  setClusterParents: () => {},
  fetchProjectGroups: () => {},
  querySequences: {},
  fetchQuerySeqs: () => {},
});

enum ParentsList {
  CLUSTER = 'cluster',
  SUNBURST = 'sunburst',
};

const ConfigContextProvider = ({ children }: any) => {
  const location = useLocation();
  const { user } = useContext(AuthContext);
  /*
   * projectId
   */
  const projectId = useMemo(() => {
    return extractURLParam(location.pathname, 'projects') ?? '';
  }, [location.pathname]);

  /*
   * config
   */
  const [config, setConfig] = useState<Config>({});
  const [projectGroups, setProjectGroups] = useState<SequenceGroupData>([]);
  const [projectGroupsLoading, setProjectGroupsLoading] = useState(true);
  const [projects, setProjects] = useState<Project[]>([]);
  const [querySequences, setQuerySeqs] = useState({});

  const fetchProjectConfig = () => {
    if (!projectId) {
      setConfig({});
      return;
    }
    sequenceApi.getProjectConfig({ projectId })
      .then(data => {
        setConfig(data);
      })
      .catch((error: AxiosError) => {
        setConfig({});
        notification.error({ message: `Get project config error:', ${error.message}` });
      });
  };

  const fetchProjectGroups = () => {
    setProjectGroups([]);
    sequenceApi.getSequenceGroups({ projectId })
      .then(seqGroups => {
        setProjectGroups(seqGroups);
      })
      .catch((error: AxiosError) => {
        notification.error({ message: `Get sequence groups error: ${error.message}` });
      })
      .finally(() => {
        setProjectGroupsLoading(false);
      });
  };

  const fetchListOfProjects = () => {
    sequenceApi.getUserProjects()
      .then(projects => {
        setProjects(projects);
      })
      .catch((error: AxiosError) => {
        notification.error({ message: `Get user projects failed: ${error.message}`, closeIcon: true });
      });
  };

  useEffect(() => {
    if (projectId && user) {
      fetchProjectConfig();
    }
  }, [projectId, user]);

  useEffect(() => {
    if (projectId) {
      fetchProjectGroups();
    } else {
      setProjectGroupsLoading(true);
    }
  }, [projectId, user]);

  useEffect(() => {
    if (user) {
      fetchListOfProjects();
    }
  }, [user]);
  /*
   * ligands
   */
  const [ligands, setLigands] = useState<Ligand[]>([]);

  const refreshLigands = () => {
    if (!projectId || !config.frontendConfig?.ligandDocking) {
      setLigands([]);
      return;
    }
    sequenceApi.getProjectLigands({ projectId })
      .then(data => {
        setLigands(data);
      })
      .catch((error: AxiosError) => {
        notification.error({ message: `Get project ligends error: ${error.message}` });
      });
  };

  useEffect(() => {
    refreshLigands();
  }, [projectId, config, user]);

  /*
   * tags
   */
  const [tags, setTags] = useState<Tag[]>([]);
  const [tagLabels, setTagLabels] = useState<Record<string, SequenceTag[]>>({});

  const refreshTags = () => {
    if (!projectId || !config.frontendConfig?.tags) {
      setTags([]);
      setTagLabels({});
      return;
    }
    sequenceApi.getProjectTags({ projectId })
      .then(projectTags => {
        setTags(projectTags);
      })
      .catch((error: AxiosError) => {
        notification.error({ message: `Get project tags error: ${error.message}` });
      });
  };

  useEffect(() => {
    refreshTags();
  }, [projectId, config, user]);

  useEffect(() => {
    // TODO: Remove all tagLabels that have no tag.
    if (!projectId || !config.frontendConfig?.tags) {
      setTagLabels({});
      return;
    }
    _.keys(tagLabels).forEach(tagId => {
      if (!tags.find(tag => tag.uuid === tagId)) {
        setTagLabels(prevState => _.omit(prevState, tagId));
      }
    });
    tags.forEach(tag => {
      sequenceApi.getTagLabels({ projectId, tagId: tag.uuid }).then(data => {
        setTagLabels(prevState => ({ ...prevState, [tag.uuid]: data }));
      }).catch(() => {
        setTagLabels(prevState => ({ ...prevState, [tag.uuid]: [] }));
      });
    });
  }, [projectId, config, tags, user]);

  /*
   * groupId
   */
  const groupId = useMemo(() => {
    const id = extractURLParam(location.pathname, 'groups');

    return _.toNumber(id) ?? undefined;
  }, [location.pathname]);

  /*
   * sequenceGroupSource
   */
  const [sequenceGroupSource, setSequenceGroupSource] = useState<(string | number)[]>([]);

  useEffect(() => {
    if (!projectId || !groupId) {
      setSequenceGroupSource([]);
      return;
    }
    sequenceApi.getSequenceGroupSource({ projectId, groupId })
      .then(data => {
        setSequenceGroupSource(data);
      })
      .catch((error: AxiosError) => {
        notification.error({ message: `Get sequences group source error: ${error.message}` });
      });
  }, [projectId, groupId]);

  /*
   * filter
   */
  const [filter, setFilter] = useState<Record<string, ColumnFilter>>({});
  const [groupInfo, setGroupInfo] = useState<SequenceInfo>({ title: '' });
  const [groupLoading, setGroupLoading] = useState<boolean>(false);

  const fetchGroupInfo = (id: number) => {
    setGroupLoading(true);

    projectId && id && sequenceApi.getSequenceGroupInfo({ projectId, groupId: id })
      .then(groupInfo => {
        setGroupInfo(groupInfo);

        if (groupInfo.queryId && !_.has(querySequences, groupInfo.queryId)) {
          fetchQuerySeqs(groupInfo.queryId);
        }
      })
      .catch((error: AxiosError) => {
        notification.error({ message: `Get group info data error: ${error.message}` });
      })
      .finally(() => {
        setGroupLoading(false);
      });
  };

  useEffect(() => {
    // Reset filter on groupId change.
    setFilter({});
    if (groupId) {
      fetchGroupInfo(groupId);
    }
  }, [groupId]);

  /*
  * clusterId
  */
  const [clusterParents, setClusterParents] = useState<Parents>({ parentNames: {}, parents: [] });
  const [sunburstParents, setSunburstParents] = useState<Parents>({ parentNames: {}, parents: [] });

  const clusterId = useMemo(() => {
    return extractURLParam(location.pathname, 'clusters');
  }, [location.pathname]);

  const chartId = useMemo(() => {
    return extractURLParam(location.pathname, 'chart');
  }, [location.pathname]);

  const getParents = (id: string, list: ParentsList) => {
    sequenceApi.getSequenceCluster({ projectId, groupId, clusterId: id, type: 'parents' })
      .then(data => {
        if (list === ParentsList.CLUSTER) {
          setClusterParents(data);
        } else {
          setSunburstParents(data);
        }
      })
      .catch((error: AxiosError) => {
        notification.error({ message: `Get parents data error: ${error.message}` });
      });
  };

  useEffect(() => {
    clusterId && getParents(clusterId, ParentsList.CLUSTER);
  }, [clusterId]);

  useEffect(() => {
    chartId && getParents(chartId, ParentsList.SUNBURST);
  }, [chartId]);

  const fetchQuerySeqs = (id: number) => {
    projectId && sequenceApi.getSequenceData({ projectId, groupId: id, limit: SEQUENCES_QUERY_LIMIT })
      .then(data => {
        setQuerySeqs(prev => {
          return {
            ...prev,
            [id]: data
          };
        });
      })
      .catch((error: AxiosError) => {
        notification.error({ message: `Get query sequence data error: ${error.message}` });
      });
  };

  return (
    <ConfigContext.Provider value={{
      config,
      ligands,
      refreshLigands,
      tags,
      tagLabels,
      refreshTags,
      sequenceGroupSource,
      filter,
      setFilter,
      projects,
      projectGroups,
      setProjectGroups,
      projectGroupsLoading,
      groupInfo,
      groupLoading,
      setGroupInfo,
      clusterParents,
      sunburstParents,
      setClusterParents,
      fetchProjectGroups,
      querySequences,
      fetchQuerySeqs,
    }}>
      {children}
    </ConfigContext.Provider>
  );
};

export { ConfigContext, ConfigContextProvider };
